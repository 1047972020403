import React from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/lib/Button";
import Modal from "react-bootstrap/lib/Modal";
import localeUtil from "keys-translations-manager-core/lib/localeUtil";
export default class UpdateProductionModal extends React.PureComponent {
	static propTypes = {
		showupdateproductionmodal: PropTypes.bool.isRequired,
		closeUpdateProductionModal: PropTypes.func.isRequired,
		// errors: PropTypes.array.isRequired,
		// importLocale: PropTypes.func.isRequired,
		// alertErrors: PropTypes.func.isRequired,
		// clearErrors: PropTypes.func.isRequired,
	};

	constructor() {
		super();
		this.close = this.close.bind(this);
		this.submit = this.submit.bind(this);
		this.state = {};
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if (
			nextProps.showupdateproductionmodal &&
			!prevState.prevShowupdateproductionmodal
		) {
			return {
				prevShowupdateproductionmodal: true,
				selectedFile: null,
				selectedLocale: null,
				selectedProject: null,
			};
		}
		if (
			!nextProps.showupdateproductionmodal &&
			prevState.prevShowupdateproductionmodal
		) {
			return {
				prevShowupdateproductionmodal: false,
			};
		}
		return null;
	}

	setLocale(locale) {
		this.setState({
			selectedLocale: locale,
		});
	}

	submit() {
		//axios.get('https://cryptic-crag-55573.herokuapp.com/api/')
	}

	close() {
		this.props.closeUpdateProductionModal();
	}

	render() {
		const { showupdateproductionmodal } = this.props;

		return (
			<Modal show={showupdateproductionmodal} onHide={this.close}>
				<Modal.Header>
					<Modal.Title>
						{localeUtil.getMsg("ui.common.updateProduction")}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<p>
						Compare Against
						https://cryptic-crag-55573.herokuapp.com/
					</p>
				</Modal.Body>
				<Modal.Footer>
					<Button
						bsSize="small"
						bsStyle="primary"
						onClick={this.submit}
					>
						{localeUtil.getMsg("ui.common.submit")}
					</Button>
					<Button bsSize="small" onClick={this.close}>
						{localeUtil.getMsg("ui.common.cancel")}
					</Button>
				</Modal.Footer>
			</Modal>
		);
	}
}
