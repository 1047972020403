import React from 'react'
import PropTypes from 'prop-types'
import Button from 'react-bootstrap/lib/Button'
import ControlLabel from 'react-bootstrap/lib/ControlLabel'
import Radio from 'react-bootstrap/lib/Radio'
import Modal from 'react-bootstrap/lib/Modal'
import localeUtil from 'keys-translations-manager-core/lib/localeUtil'
import configUtil from '../../configUtil'
import AlertPanel from '../input/AlertPanel'
import { Alert } from 'react-bootstrap'

const locales = configUtil.getLocales()
const projects = configUtil.getCasinos()

export default class PublishModal extends React.PureComponent {
	static propTypes = {
		showPublishModal: PropTypes.bool.isRequired,
		closePublishModal: PropTypes.func.isRequired,
		errors: PropTypes.array.isRequired,
		alertErrors: PropTypes.func.isRequired,
		clearErrors: PropTypes.func.isRequired,
		publishLocale: PropTypes.func.isRequired,
		successfullPublish: PropTypes.object.isRequired
	};
    constructor() {
		super();
		this.close = this.close.bind(this);
		this.submit = this.submit.bind(this);
		this.state = {
			prevShowpublishmodal: false,
			selectedLocale: null,
			selectedProject: null,
		}
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		let newState = {...nextProps};
		if (nextProps.showPublishModal && !prevState.prevShowpublishmodal) {
			newState = {
				...newState,
				prevShowpublishmodal: true,
				selectedLocale: null,
				selectedProject: null,
				successfullPublish: null
			};
		}
		if (!nextProps.showPublishModal && prevState.prevShowpublishmodal) {
			newState = {
				...newState,
				prevShowpublishmodal: false,
			}
		}

		return newState;
	}

	setProject(project) {
		this.setState({
			selectedProject: project
		})
	}

	setLocale(locale) {
		this.setState({
			selectedLocale: locale
		})
	}

	async submit(env) {
		const project = this.state.selectedProject;
		const locale = this.state.selectedLocale;
		this.props.alertErrors([]);

		if (!env || !project || !locale) {
			this.props.alertErrors([{
				type: 'emptyfield',
				action: "i",
				params: {env, project, locale},
				match: ['Environment', 'Project', 'Locale']
			}]);
		}

		this.props.publishLocale({env, project, locale})
	}

	close() {
		this.props.closePublishModal()
	}

	render() {
		const me = this, {
			showPublishModal,
			errors,
			clearErrors,
			successfullPublish
		} = this.props;

		return (
			<Modal show={showPublishModal} onHide={this.close}>
				<Modal.Header>
					<Modal.Title>
						{localeUtil.getMsg("ui.common.publish")}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<AlertPanel errors={errors} clearErrors={clearErrors} action="i"/>
					{ !!successfullPublish && 
						<Alert bsStyle="success">
							{localeUtil.getMsg("ui.success.publish", successfullPublish.project, successfullPublish.locale, successfullPublish.env)}
						</Alert>
					}
					{/* PROJECT */}
					<div className="app-radio-group">
						<ControlLabel>
							<span className="app-input-asterisk">* </span>
							<span style={{marginRight: 20}}>{localeUtil.getMsg("ui.common.select-casino-project")}:</span>
						</ControlLabel>
						{projects.map(function(e){
							let {id, name} = e
							return (
								<Radio inline key={id} name="project" value={id}
									checked={me.state.selectedProject===id}
									onChange={me.setProject.bind(me, id)}>{name}</Radio>
							);
						})}
					</div>
					{/* LOCALE */}
					<div className="app-radio-group">
						<ControlLabel>
							<span className="app-input-asterisk">* </span>
							<span style={{marginRight: 20}}>{localeUtil.getMsg("ui.common.select-locale")}:</span>
						</ControlLabel>
						{locales.map(function(e){
							return (
								<Radio inline key={e} name="locale" value={e}
									checked={me.state.selectedLocale===e}
									onChange={me.setLocale.bind(me, e)}>{e}</Radio>
							);
						})}
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Button bsSize="small" bsStyle="primary" onClick={this.submit.bind(this, 'staging')}>
						{localeUtil.getMsg("ui.common.staging")}
					</Button>
					<Button bsSize="small" bsStyle="primary" onClick={this.submit.bind(this, 'production')}>
						{localeUtil.getMsg("ui.common.production")}
					</Button>
					<Button bsSize="small" onClick={this.close}>
						{localeUtil.getMsg("ui.common.cancel")}
					</Button>
				</Modal.Footer>
			</Modal>
		);
	}
}
