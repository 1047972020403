export const LOAD_MESSAGES = "LOAD_MESSAGES";

export const LOAD_COUNTS = "LOAD_COUNTS";

export const ALERT_ERRORS = "ALERT_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";

export const ADD_TRANSLATION = "ADD_TRANSLATION";
export const LOAD_TRANSLATIONS = "LOAD_TRANSLATIONS";
export const REMOVE_TRANSLATION = "REMOVE_TRANSLATION";
export const UPDATE_TRANSLATION = "UPDATE_TRANSLATION";
export const IMPORT_LOCALE = "IMPORT_LOCALE";
export const MERGE_TRANSLATIONS = "MERGE_TRANSLATIONS";
export const PUBLISH_LOCALE = "PUBLISH_LOCALE";
export const CLEAR_PUBLISH_LOCALE = "CLEAR_PUBLISH_LOCALE";

export const FIND_MERGEABLE = "FIND_MERGEABLE";

export const LOAD_HISTORY = "LOAD_HISTORY";

export const SHOW_EDITMODAL = "SHOW_EDITMODAL";
export const CLOSE_EDITMODAL = "CLOSE_EDITMODAL";
export const SHOW_CONFIRMMODAL = "SHOW_CONFIRMMODAL";
export const CLOSE_CONFIRMMODAL = "CLOSE_CONFIRMMODAL";
export const SHOW_HISTORYMODAL = "SHOW_HISTORYMODAL";
export const CLOSE_HISTORYMODAL = "CLOSE_HISTORYMODAL";
export const CLOSE_MERGEMODAL = "CLOSE_MERGEMODAL";
export const SHOW_IMPORTMODAL = "SHOW_IMPORTMODAL";
export const CLOSE_IMPORTMODAL = "CLOSE_IMPORTMODAL";
export const SHOW_PUBLISHMODAL = "SHOW_PUBLISHMODAL";
export const CLOSE_PUBLISHMODAL = "CLOSE_PUBLISHMODAL";
export const SHOW_UPDATEPRODUCTIONMODAL = "SHOW_UPDATEPRODUCTIONMODAL";
export const CLOSE_UPDATEPRODUCTIONMODAL = "CLOSE_UPDATEPRODUCTIONMODAL";
export const RELOAD_DATA = "RELOAD_DATA";

export const SHOW_MESSAGEPOPUP = "SHOW_MESSAGEPOPUP";
export const CLOSE_MESSAGEPOPUP = "CLOSE_MESSAGEPOPUP";

export const END_DATACHANGE = "END_DATACHANGE";

export const LOAD_TREE_DATA = "LOAD_TREE_DATA";

export const SHOW_TOOLTIP = "SHOW_TOOLTIP";
export const HIDE_TOOLTIP = "HIDE_TOOLTIP";
