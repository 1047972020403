module.exports = {
	/**
	 * If you change the configurations,
	 * don't forget to rebuild the code (npm run build) and
	 * restart the server (npm run start).
	 */
	locales: ["en", "pt"],
	projects: [
		// make sure the ids are 'String' type
		{ id: "portugal", name: "Portugal" },
	],
	enableNotifications: false,
};
