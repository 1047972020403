import React, { memo } from 'react'
import PropTypes from 'prop-types'
import localeUtil from 'keys-translations-manager-core/lib/localeUtil'

// eslint-disable-next-line no-unused-vars
const DropdownMenu = memo(({ lang, loadMessages, showImportModal, showPublishModal, showUpdateProductionModal, findMergeable }) => {
	const loadMsg = ln => {
		if (ln !== lang) {
			loadMessages(ln);
		}
	}
	return (
		<ul className="nav navbar-top-links navbar-right">
			{/* <li className="dropdown" title={localeUtil.getMsg("ui.common.compare")}>
				<a className="dropdown-toggle" href="#" onClick={event => {
					if (event) {
						event.preventDefault();
					}
					showUpdateProductionModal();
				}}>
					<i className="fas fa-arrows-alt-v fa-fw fa-lg" />
				</a>
			</li> */}
			
			<li className="dropdown" title={localeUtil.getMsg("ui.common.import")}>
				<a className="dropdown-toggle" href="#" onClick={event => {
					if (event) {
						event.preventDefault();
					}
					showPublishModal();
				}}>
					<i className="fas fa-arrows-alt-v fa-fw fa-lg" />
				</a>
			</li>
			<li className="dropdown" title={localeUtil.getMsg("ui.common.import")}>
				<a className="dropdown-toggle" href="#" onClick={event => {
					if (event) {
						event.preventDefault();
					}
					showImportModal();
				}}>
					<i className="fa fa-file-import fa-fw fa-lg"/>
				</a>
			</li>
			{/* <li className="dropdown" title={localeUtil.getMsg("ui.common.merge")}>
				<a className="dropdown-toggle" href="#" onClick={event => {
					// istanbul ignore next
					if (event) {
						event.preventDefault();
					}
					findMergeable();
				}}>
					<i className="fas fa-share-alt fa-flip-horizontal fa-fw fa-lg"/>
				</a>
			</li> */}
			<li className="dropdown" title={localeUtil.getMsg("ui.common.language")}>
				<a className="dropdown-toggle" data-toggle="dropdown" href="#">
					<i className="fas fa-globe fa-fw fa-lg"/>
					{' '}
					<i className="fa fa-caret-down"/>
				</a>
				<ul className="dropdown-menu dropdown-user">
					<li><a href="#" onClick={event => {
							/* istanbul ignore next */
							if (event) {
								event.preventDefault();
							}
							loadMsg("en-US");
						}}>
						<i className="fas fa-globe-americas fa-fw"/> English
					</a></li>
				</ul>
			</li>
		</ul>
	);
});

DropdownMenu.propTypes = {
	lang: PropTypes.string.isRequired,
	loadMessages: PropTypes.func.isRequired,
	showImportModal: PropTypes.func.isRequired,
	showPublishModal: PropTypes.func.isRequired,
	showUpdateProductionModal: PropTypes.func.isRequired,
	findMergeable: PropTypes.func.isRequired
};

export default DropdownMenu
