"use strict";
const config = require("../ktm.config"),
	{ locales, projects } = config,
	projectIdList = [],
	projectNames = {};

projects.map((e) => {
	projectIdList.push(e.id);
	projectNames[e.id] = e.name;
});

// Exclude the general project
function filterCasinoProjects(project) {
	return project?.id !== "general";
}

module.exports = {
	getHost() {
		if (process.env.NODE_ENV === "test") {
			return `http://localhost:${process.env.PORT || 3000}`;
		}
		return "";
	},
	getLocales() {
		return locales;
	},
	getProjects() {
		return projects;
	},
	getCasinos() {
		return projects.filter(filterCasinoProjects);
	},
	getProjectIdList() {
		return projectIdList;
	},
	getProjectName(projectId) {
		return projectNames[projectId];
	},
};
